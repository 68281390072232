import { useState, useEffect } from "react";
import ProductAPI from "../../API/product";
import ProductList from "./ProductList";
import "./css/PatentRegistPop.scss";
import PatentListOfProduct from "./PatentListOfProduct";

const PatentRegistPop = ({ setIsRegistPopOpen, setIsNeedGetProduct }) => {
  const [keyword, setKeyword] = useState(""); // 입력된 제품명
  const [productList, setProductList] = useState([]); // 전체 제품 리스트 (기존 + 새로 등록된)
  const [patentListByProduct, setPatentListByProduct] = useState([]); // 제품에 연결된 특허
  const [selectedProduct, setSelectedProduct] = useState(null); // 선택된 제품 idx
  const [patentSort, setPatentSort] = useState("default");

  // 팝업이 열릴 때 기존 제품 목록을 API로부터 불러오는 함수
  useEffect(() => {
    const fetchProductList = async () => {
      const result = await ProductAPI.getProductListCompany().then(
        (res) => res.data
      );
      setProductList(result); // API로부터 받은 기존 제품 리스트를 상태에 저장
    };
    fetchProductList();
  }, []);

  // 새로운 제품을 API를 통해 추가하는 함수
  const addNewProduct = async (name) => {
    const newItem = { name };

    // API 호출하여 새로운 제품을 등록
    await ProductAPI.addProduct(newItem);

    // 등록 후 최신 제품 목록을 다시 불러와서 상태 업데이트
    const updatedList = await ProductAPI.getProductListCompany().then(
      (res) => res.data
    );
    setProductList(updatedList);
  };

  // 등록 버튼을 눌렀을 때 실행되는 함수
  const handleAddProduct = async () => {
    if (keyword) {
      await addNewProduct(keyword); // 입력된 제품명을 API를 통해 추가
      setKeyword(""); // 입력 필드를 초기화
      setIsNeedGetProduct(true);
    }
  };

  const getProductName = (idx) => {
    return productList.find((item) => item.idx === idx);
  };

  const getPatentListOfProduct = (idx, sorting) => {
    // 제품의 특허 가져오기
    setPatentSort(sorting);
    setSelectedProduct(idx);
    ProductAPI.getConnectedPatentList({
      product_idx: idx,
      sort: sorting ? sorting : "default",
    }).then((res) => {
      console.log("getPatentListOfProduct:", res);
      if (res.status === 200) {
        setPatentListByProduct(res.data?.items || []);
      }
    });
  };

  const insertPatentProduct = (patent_idx, sorting) => {
    // 특허와 제품 연결
    ProductAPI.insertPatentProduct({
      product_idx: selectedProduct,
      patent_idx: patent_idx,
    }).then((res) => {
      console.log("insertPatentProduct:", res);
      if (res.status === 200) {
        if (res.data) {
          getPatentListOfProduct(selectedProduct, sorting);
        }
        setIsNeedGetProduct(true);
      }
    });
  };

  const deletePatentProduct = (patent_idx, sorting) => {
    // 특허와 제품 연결 해제
    ProductAPI.deletePatentProduct({
      product_idx: selectedProduct,
      patent_idx: patent_idx,
    }).then((res) => {
      console.log("deletePatentProduct:", res);
      if (res.status === 200) {
        if (res.data) {
          getPatentListOfProduct(selectedProduct, sorting);
          setIsNeedGetProduct(true);
        }
      }
    });
  };

  return (
    <div id="PatentRegistPop">
      <div className="pop_wrap">
        <div className="tit_box">
          <strong className="tit">제품 추가하기</strong>
        </div>
        <div className="product_sch">
          <input
            type="text"
            placeholder="입력해주세요."
            className="keyword"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)} // 입력 필드 업데이트
          />
          <button className="regist_btn" onClick={handleAddProduct}>
            등록
          </button>
        </div>
        <div className="prodcut_tbl tbl_box">
          <ProductList
            productList={productList}
            setPatentListByProduct={setPatentListByProduct}
            setSelectedProduct={setSelectedProduct}
            getPatentListOfProduct={getPatentListOfProduct}
            sort={patentSort}
            setIsNeedGetProduct={setIsNeedGetProduct}
          />
          {/* 추가된 제품 리스트를 넘김 */}
        </div>
        <div className="tit_box">
          <strong className="tit">특허 제품 연결하기</strong>
          {selectedProduct ? (
            <div className="tag">{getProductName(selectedProduct).name}</div>
          ) : (
            <span className="no_tag">제품을 선택해주세요.</span>
          )}
        </div>
        {/* <div className="patent_sch">
          <input type="text" placeholder="검색어를 입력해주세요." />
          <button className="sch_btn">
            <i className="icon_search_blue"></i>
          </button>
        </div> */}
        <div className="patent_tbl tbl_box custom_scroll">
          <PatentListOfProduct
            patentList={patentListByProduct}
            insertPatentProduct={insertPatentProduct}
            deletePatentProduct={deletePatentProduct}
            sort={patentSort}
            setPatentSort={setPatentSort}
            getPatentListOfProduct={getPatentListOfProduct}
            selectedProduct={selectedProduct}
          />
        </div>
        <button
          className="close"
          onClick={() => {
            setIsRegistPopOpen(false);
          }}
        >
          <i className="icon_exit_gray"></i>
        </button>
      </div>
    </div>
  );
};

export default PatentRegistPop;
