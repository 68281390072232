import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import memberContext from "../../store/Member";
import "./Header.scss";
import UserTab from "./UserTab";
import UnEventContext from "../../store/UnEvent";
import WithPreventScroll from "../../HOC/WithPreventScroll";
import CommonContext from "../../store/Common";
import MobilePopupContext from "../../store/MobilePopup";
import CommonAPI from "../../API/common";
import GwAPI from "../../API/groupware";
import { headerData } from "./headerData";

function RenewalHeader({ history, state }) {
  const location = useLocation();
  const unEvent = useContext(UnEventContext);
  const memberInfo = useContext(memberContext);
  const { isMobile } = useContext(CommonContext);
  const { mobilePopup, setMobilePopup } = useContext(MobilePopupContext);
  const auth = memberInfo.auth;
  const isLogin = auth != null && auth != undefined;
  const isCompany = window.$Global.checkAuth("특허회사", memberInfo.auth);
  const isInvestor = window.$Global.checkAuth("투자자", memberInfo.auth);
  const [moMenuShow, setMoMenuShow] = useState(false);
  const [showMenuTitle, setShowMenuTitle] = useState(
    headerData(
      localStorage.getItem("package_idx") || "",
      localStorage.getItem("package_expiration_at") || 0
    )
  );
  const isViewTypeIpnow = window.$Global.isIpnow(location);
  const isCalcPath = window.location.pathname === "/calc";
  const isMain = window.location.pathname === "/";
  const [isHoldings, setIsHoldings] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMenuWidthOver, setIsMenuWidthOver] = useState(
    window.innerWidth > 1640
  );
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    CommonAPI.log().then(() => {
      console.log("logging success");
    });
    GwAPI.getUserInfo().then((res) => {
      if (res.data?.userInfo) {
        setIsHoldings(res.data?.userInfo?.isHoldings || false);
      }
    });
  }, [location]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      setMoMenuShow(false);
      setIsMobileMenuOpen(false);
    });

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setIsMenuWidthOver(window.innerWidth > 1640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      unlisten();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobileMenuOpen) {
      document.getElementsByTagName("body")[0].classList.add("lock");
      menuRef?.current?.classList.add("open");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("lock");
      menuRef?.current?.classList.remove("open");
    }
  }, [isMobileMenuOpen]);

  const onClickLogout = () => window.$Global.logout("self");

  const redirect = () => window.$Global.redirectHome(auth);

  const SettingByHOC = () => {
    return (
      <div
        className={`dimmed ${moMenuShow ? "show" : "hide"}`}
        onClick={() => setMoMenuShow(false)}
      />
    );
  };

  const MenuBtns = ({ mobile }) => {
    const [openMenu, setOpenMenu] = useState({});

    const handleMenuClick = (index) => {
      setOpenMenu((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));
    };

    //${isMobileMenuOpen ? "open" : ""}
    return (
      <div
        ref={mobile ? menuRef : null}
        className={`btn_wrapper custom_scroll ${
          !isMenuWidthOver && !isMobile ? "md_hd" : ""
        } ${mobile ? "mobile" : ""} `}
      >
        {mobile ? (
          <div className="mobile_menu_close">
            <button onClick={() => setIsMobileMenuOpen((prev) => !prev)}>
              <i className="icon_exit"></i>
            </button>
          </div>
        ) : (
          ""
        )}
        {showMenuTitle.map((el, index) => {
          const isOpen = openMenu[index];
          return (
            <div className="btn_item" key={index}>
              <div
                className={`menu_item  ${el.isShow ? "open" : "close"}`}
                style={mobile ? null : { backgroundColor: el.color }}
                onClick={
                  mobile
                    ? el.child?.length > 0
                      ? () => handleMenuClick(index)
                      : () => history.push(el.pathname)
                    : () => el.pathname && history.push(el.pathname)
                }
              >
                <p>
                  <span>
                    {mobile ? (
                      <i
                        className={`${el.icon || "fi-rs-menu-dots-vertical"}`}
                      ></i>
                    ) : (
                      ""
                    )}
                    {el.content}
                  </span>
                  {el.child?.length > 0 && mobile && (
                    <i
                      className={`${
                        isOpen
                          ? "icon_more_arrow_up"
                          : "icon_more_arrow_down_black"
                      }`}
                    ></i>
                  )}
                </p>
                <div className={`child_tab ${isOpen ? "on" : ""}`}>
                  {el.child?.map((item) => {
                    return (
                      <div
                        style={
                          mobile
                            ? null
                            : {
                                border: `1px solid ${el.color}`,
                                borderRadius: "16px",
                                color: el.color,
                              }
                        }
                        onClick={() => {
                          const dummy = Math.floor(Math.random() * 1000000); // 0에서 999999 사이의 난수 생성
                          const currentUrl = item.pathname;
                          const separator = currentUrl.includes("?")
                            ? "&"
                            : "?";

                          history.push(
                            `${currentUrl}${separator}dummy=${dummy}`
                          );
                        }}
                      >
                        {item.content}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
        {isHoldings ? (
          <div className="btn_item">
            <div
              className="menu_item"
              style={mobile ? null : { backgroundColor: "rgb(5, 221, 123)" }}
              onClick={(event) => {
                event.stopPropagation();
                history.push("/holdings/statistic");
              }}
            >
              <p>
                <span>
                  {mobile ? <i className="fi-rs-building"></i> : ""}
                  지주사
                </span>
              </p>
              <div className="child_tab">
                <div
                  style={{
                    border: "1px solid rgb(5, 221, 123)",
                    borderRadius: "16px",
                    color: "rgb(5, 221, 123)",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push("/holdings/project");
                  }}
                >
                  네트워킹 룸
                </div>
                <div
                  style={{
                    border: "1px solid rgb(5, 221, 123)",
                    borderRadius: "16px",
                    color: "rgb(5, 221, 123)",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push("/holdings/current");
                  }}
                >
                  기업별 현황
                </div>
                <div
                  style={{
                    border: "1px solid rgb(5, 221, 123)",
                    borderRadius: "16px",
                    color: "rgb(5, 221, 123)",
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push("/holdings/info");
                  }}
                >
                  통계
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const ComponentWithDimmed = WithPreventScroll(SettingByHOC, moMenuShow);

  let exceptCondition;

  if (isMobile) {
    exceptCondition = !isMobile || isCalcPath;
  } else {
    exceptCondition = (!isLogin && !isMobile) || isCalcPath;
  }

  if (exceptCondition) return null;

  const RenewalHeader = () => {
    return (
      <>
        <div className="wrap_header">
          <div className="header_left">
            <div style={header_h1}>
              {isViewTypeIpnow ? (
                <img
                  src={
                    require("../../assets/images/common/logo_ipnow.svg").default
                  }
                  style={{ position: "relative", width: 84 }}
                  onClick={redirect}
                  alt="이미지"
                />
              ) : (
                <img
                  src={
                    require("../../assets/images/common/logo_findBiz.svg")
                      .default
                  }
                  style={{ width: 96 }}
                  onClick={() => {
                    state === "off"
                      ? history.push("/taskManage/list")
                      : redirect();
                  }}
                  alt="이미지"
                />
              )}
            </div>
            <div className="line" />
            {!isMenuWidthOver && !isMobile && (
              <button
                className="burger_menu"
                onClick={() => {
                  setIsMobileMenuOpen((prev) => !prev);
                }}
              >
                <i className="fi-rs-burger-menu"></i>
              </button>
            )}
            {state !== "off" && (
              <div className="dashboard" onClick={() => history.push("/")}>
                <p>DASH board</p>
              </div>
            )}

            {isMenuWidthOver && <MenuBtns />}
          </div>
          <div className="wrap_header_utill">
            {isLogin ? (
              <UserTab
                member={memberInfo}
                onClickLogout={onClickLogout}
                unEvent={unEvent}
              />
            ) : (
              <div className="user_tab">
                <ul className="header_user_tab_ul">
                  <li style={header_user_tab_li}>
                    <Link to="/signup/type" style={{ ...link }}>
                      회원가입
                    </Link>
                  </li>
                  <li style={header_user_tab_li}>
                    <Link to="/login" style={{ ...link }}>
                      로그인
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
          {!isMenuWidthOver && <MenuBtns mobile={true} />}
        </div>
        {/* {!isMenuWidthOver && !isMobile && <MenuBtns />} */}
      </>
    );
  };
  return (
    <div
      id="Header"
      className={`${isMobile ? "mo" : ""} ${isLogin ? "" : "no_auth"} ${
        isMain ? "main" : ""
      }`}
    >
      <RenewalHeader />
      {/*<div className="wrap mo">*/}
      {/*  <div className="menu_top">*/}
      {/*    {mobilePopup.mobileShow || mobilePopup.historyMobileHeaderTitle ? (*/}
      {/*      <>*/}
      {/*        /!* X 버튼 및 타이틀 *!/*/}
      {/*        <button*/}
      {/*          type="button"*/}
      {/*          className="btnDel"*/}
      {/*          onClick={onClickClosePopup}*/}
      {/*        >*/}
      {/*          창 닫기*/}
      {/*        </button>*/}
      {/*        <div className="menu_top_title">*/}
      {/*          {mobilePopup.mobileHeaderTitle}*/}
      {/*        </div>*/}

      {/*        /!* < 버튼 및 타이틀 *!/*/}
      {/*        /!* <button type="button" className="prevDel">이전 페이지로</button>*/}
      {/*                              <div className="menu_top_title">제안서 샘플 마켓</div>*/}
      {/*                              <div className="btns"></div> *!/*/}
      {/*      </>*/}
      {/*    ) : (*/}
      {/*      <>*/}
      {/*        <button*/}
      {/*          className={isViewTypeIpnow ? "m_logo1" : "m_logo2"}*/}
      {/*          onClick={redirect}*/}
      {/*        >*/}
      {/*          홈으로*/}
      {/*        </button>*/}
      {/*        <div className="btns">*/}
      {/*          {*/}
      {/*            // isLogin &&*/}
      {/*            isViewTypeIpnow ? (*/}
      {/*              <button*/}
      {/*                className="btn_change btn_findbiz"*/}
      {/*                onClick={() => onClickChangeView(1)}*/}
      {/*              >*/}
      {/*                BizNavi 전환*/}
      {/*              </button>*/}
      {/*            ) : (*/}
      {/*              <button*/}
      {/*                className="btn_change btn_ipnow"*/}
      {/*                onClick={() => onClickChangeView(0)}*/}
      {/*              >*/}
      {/*                IPNOW 전환*/}
      {/*              </button>*/}
      {/*            )*/}
      {/*          }*/}
      {/*          {isLogin ? (*/}
      {/*            !isInvestor && (*/}
      {/*              <Link*/}
      {/*                to="/unEvent"*/}
      {/*                className={`icon_m_bell${*/}
      {/*                  unEvent.unConfirmEventCnt > 0 ? "_on" : ""*/}
      {/*                }`}*/}
      {/*              />*/}
      {/*            )*/}
      {/*          ) : (*/}
      {/*            // <Link to="/login" className="icon_m_login">*/}
      {/*            //   로그인*/}
      {/*            // </Link>*/}

      {/*            <Link className="btn_login" to="/login">*/}
      {/*              로그인*/}
      {/*            </Link>*/}
      {/*          )}*/}
      {/*          {moMenuShow ? (*/}
      {/*            <button*/}
      {/*              className="icon_exit"*/}
      {/*              onClick={() => setMoMenuShow(false)}*/}
      {/*            />*/}
      {/*          ) : (*/}
      {/*            <button*/}
      {/*              className="icon_m_menu"*/}
      {/*              onClick={() => setMoMenuShow(true)}*/}
      {/*            />*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="mo">*/}
      {/*  <div className="more_menu">*/}
      {/*    <ComponentWithDimmed />*/}
      {/*    <div className={`menus ${moMenuShow ? "menu_animation" : ""}`}>*/}
      {/*      {isLogin && !isInvestor && (*/}
      {/*        <>*/}
      {/*          <div className="myinfo">*/}
      {/*            <div className="access_account">*/}
      {/*              <h2>{memberInfo.name}님</h2>*/}
      {/*              {memberInfo.company != "null" && (*/}
      {/*                <p>{memberInfo.company}</p>*/}
      {/*              )}*/}
      {/*            </div>*/}
      {/*            <div className="btns">*/}
      {/*              <Link to="/mypage/mobile" className="btn_mypage">*/}
      {/*                마이페이지*/}
      {/*                <i className="icon_arrow_right_orange" />*/}
      {/*              </Link>*/}
      {/*              {!isInvestor && window.$Global.isIpnow() && (*/}
      {/*                <Link to="/manage/mobile" className="btn_auth">*/}
      {/*                  관리*/}
      {/*                  <i className="icon_more_arrow_right_white" />*/}
      {/*                </Link>*/}
      {/*              )}*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          {isCompany && window.$Global.isIpnow(location) && (*/}
      {/*            <>*/}
      {/*              <div className="m_item">*/}
      {/*                <p>*/}
      {/*                  IP 관리*/}
      {/*                  <i className="icon_more_arrow_down" />*/}
      {/*                </p>*/}
      {/*                <ul>*/}
      {/*                  <li>*/}
      {/*                    <Link to="/patent/mlist">특허 관리</Link>*/}
      {/*                  </li>*/}
      {/*                  <li>*/}
      {/*                    <Link to="/tradeMark/mlist">상표 관리</Link>*/}
      {/*                  </li>*/}
      {/*                  <li>*/}
      {/*                    <Link to="/design/mlist">디자인권 관리</Link>*/}
      {/*                  </li>*/}
      {/*                </ul>*/}
      {/*              </div>*/}
      {/*            </>*/}
      {/*          )}*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*      {!isInvestor && (*/}
      {/*        <div className="m_item">*/}
      {/*          <p>*/}
      {/*            기업 정보*/}
      {/*            <i className="icon_more_arrow_down" />*/}
      {/*          </p>*/}
      {/*          <ul>*/}
      {/*            <li>*/}
      {/*              <Link to="/people">구성원 소개</Link>*/}
      {/*            </li>*/}
      {/*          </ul>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*      {!isLogin && (*/}
      {/*        <>*/}
      {/*          <div className="m_item">*/}
      {/*            <Link to="/intro/price">*/}
      {/*              이용가격*/}
      {/*              <i className="icon_more_arrow_right" />*/}
      {/*            </Link>*/}
      {/*          </div>*/}
      {/*          <div className="m_item">*/}
      {/*            <Link to="/intro/service">*/}
      {/*              고객지원*/}
      {/*              <i className="icon_more_arrow_right" />*/}
      {/*            </Link>*/}
      {/*          </div>*/}
      {/*        </>*/}
      {/*      )}*/}
      {/*      {isLogin && (*/}
      {/*        <div className="m_item">*/}
      {/*          <p onClick={onClickLogout}>*/}
      {/*            로그아웃*/}
      {/*            <i className="icon_more_arrow_right" />*/}
      {/*          </p>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*      <div className="wrap_agree">*/}
      {/*        <Link to="/terms/use">서비스 이용약관</Link>*/}
      {/*        <Link to="/terms/private">개인정보 개인정보</Link>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

const header_h1 = {
  lineHeight: "40px",
  fontSize: "20px",
  color: "#ffffff",
  textAlign: "right",
  cursor: "pointer",
  display: "flex",
};

const link = {
  display: "block",
  width: "80px",
  textAlign: "center",
};

const margin_left = {
  marginLeft: "18px",
};

const font_size_11 = {
  fontSize: "11px",
};

const pointer = {
  cursor: "pointer",
};

const header_user_tab_li = {
  display: "flex",
  alignItems: "center",
  ...margin_left,
  ...font_size_11,
  ...pointer,
};

export default RenewalHeader;
