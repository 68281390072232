import { useEffect, useState } from "react";
import GroupwareSideBar from "../GroupwareSideBar";
import { groupCashflow } from "../groupwarePath";
import GwAPI from "../../../API/groupware";
import "./scss/GroupwareCashflow.scss";
import TopBanner from "./TopBanner";
import Top from "./Top";
import {
  Link,
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import CashflowHistoryList from "./CashflowHistoryList";

const CashflowReport = () => {
  const step = "view";
  let history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [reportId, setReportId] = useState(
    Number(searchParams.get("report_id"))
  ); // 리포트 id
  const [reportSumData, setReportSumData] = useState({
    baseCashAmount: 0,
    totalIncome: 0,
    totalOutcome: 0,
  }); // 리포트 금액 데이터
  const [monGraphData, setMonGraphData] = useState([]); // 월별 그래프 데이터
  const [mixedCahrtData, setMixedCahrtData] = useState({});
  const [reportInfo, setReportInfo] = useState({});
  const [startDate, setStartDate] = useState({ year: 0, month: 0, day: 0 });
  const [triggerSetData, setTriggerSetData] = useState(false);
  const chartOption = {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxWidth: 11,
        boxHeight: 11,
        usePointStyle: true,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };
  const links = [
    {
      title: "투자사 컨택하러 가기",
      text: "(주)아이피나우는 5개월 내로 투자유치가 필요할 것으로 예측됩니다.",
      link: "/cashflow/find/investor",
    },
    {
      title: "IP담보대출 준비하러 가기",
      text: "(주)아이피나우는 4개월 내로 대출이 필요할 것으로 예측됩니다.",
      link: "/mortgageLoan/list",
    },
    {
      title: "대출은행 알아보기",
      text: "(주)아이피나우는 4개월 내로 대출이 필요할 것으로 예측됩니다.",
      link: "/cashflow/find/bank",
    },
    {
      title: "상표 판매하기",
      text: "(주)아이피나우는 5개월 내로 현금확보가 필요할 것으로 예측됩니다.",
      link: "/trade/list",
    },
  ];

  // 보유금액, 현금 유입, 유출 금액 가져오기
  const getReportSum = () => {
    return GwAPI.getReportSum({ report_id: reportId })
      .then((res) => {
        if (res.status === 200) {
          return res;
        } else {
          alert("데이터 가져오기 중 오류가 발생했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetching cashflow data failed:", error);
        alert("데이터 가져오기 중 오류가 발생했습니다");
        return false;
      });
  };

  const fetchReportData = async () => {
    if (!reportId) {
      alert("해당 리포트를 찾을 수 없습니다.");
      history.push("/groupware/cashflow/list");
    } else {
      const reportSum = await getReportSum();
      const reportSumData = reportSum?.data?.previewData;
      //console.log("reportSumData:", reportSumData);
      setReportSumData({
        baseCashAmount: formatValue(reportSumData.baseCashAmount),
        totalIncome: formatValue(reportSumData.totalIncome),
        totalOutcome: formatValue(reportSumData.totalOutcome),
      });
    }
  };

  // 그래프 데이터 가져오기
  const getMonthGraphData = () => {
    return GwAPI.getMonthGraph({ report_id: reportId })
      .then((res) => {
        if (res.status === 200) {
          return res;
        } else {
          alert("데이터 가져오기 중 오류가 발생했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetching cashflow data failed:", error);
        alert("데이터 가져오기 중 오류가 발생했습니다");
        return false;
      });
  };

  const fetchGraphData = async () => {
    if (!reportId) {
      alert("해당 리포트를 찾을 수 없습니다.");
      history.push("/groupware/cashflow/list");
    } else {
      const monthGraph = await getMonthGraphData();
      const monthGraphData = monthGraph?.data?.monthlyCashFlow;
      //console.log("monthGraphData:", monthGraphData);
      setMonGraphData(monthGraphData);
    }
  };

  const getOneReportInfo = () => {
    return GwAPI.getOneReportInfo({ report_id: reportId })
      .then((res) => {
        ////console.log("getOneReportInfo:", res);
        if (res.status === 200) {
          return res;
        } else {
          alert("데이터 가져오기 중 오류가 발생했습니다");
          return false;
        }
      })
      .catch((error) => {
        console.error("Fetching report data failed:", error);
        alert("데이터 가져오기 중 오류가 발생했습니다");
        return false;
      });
  };

  const fetchOneReportInfo = async () => {
    if (!reportId) {
      alert("해당 리포트를 찾을 수 없습니다.");
      history.push("/groupware/cashflow/list");
    } else {
      const reportInfo = await getOneReportInfo();
      const reportInfoData = reportInfo?.data?.reportEntity;
      setReportInfo(reportInfoData);
      setStartDate(formatDate(reportInfoData.reg_date));
    }
  };

  const formatValue = (value) => {
    // 입력값의 부호를 기억하고 절대값으로 변환
    const isNegative = value < 0;
    let realWon = Math.abs(value);

    // 억 단위와 만 단위로 분리
    const billion = Math.floor(realWon / 10000 / 10000);
    const tenThousand = (realWon / 10000) % 10000;

    // 억 단위가 있을 경우 억 단위 포함하여 표시, 만 단위만 있을 경우 만 단위만 표시
    const result = `${billion > 0 ? `${billion}억 ` : ""}${
      tenThousand > 0 ? `${Math.floor(tenThousand)}만원` : "0만원"
    }`;

    // 음수였다면 결과 앞에 "-" 추가
    return isNegative ? `-${result}` : result;
  };

  const formatDate = (date) => {
    const newDate = new Date(date);

    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const day = newDate.getDate();

    return { year: year, month: month, day: day };
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchReportData();
      await fetchGraphData();
      await fetchOneReportInfo();
      // 모든 데이터 로딩 후 차트 데이터 설정
      setTriggerSetData(true);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchReportData();
      await fetchGraphData();
      await fetchOneReportInfo();
      // 모든 데이터 로딩 후 차트 데이터 설정
      setTriggerSetData(true);
    };

    fetchData();

    window.scrollTo({
      top: 0, // 상단으로 이동
      behavior: "smooth", // 스무스 스크롤 옵션
    });
  }, [reportId]);

  useEffect(() => {
    setReportId(Number(searchParams.get("report_id")));
  }, [location]);

  useEffect(() => {
    //console.log("triggerSetData:", triggerSetData);
    if (triggerSetData) {
      let monthArr = [];
      let incomeArr = [];
      let outcomeArr = [];
      let totalArr = [];
      let baseArr = [];

      for (let i = 0; i < 12; i++) {
        monthArr.push(
          startDate.month + i > 12
            ? startDate.month + i - 12 + "월"
            : startDate.month + i + "월"
        );
      }
      monGraphData.forEach((el) => {
        incomeArr.push(el.income / 1000);
        outcomeArr.push(el.outcome / 1000);
        totalArr.push(el.totalAmount / 1000);
        baseArr.push(el.baseCashAmount / 1000);
      });

      setMixedCahrtData({
        labels: monthArr,
        datasets: [
          // {
          //   label: "기초",
          //   data: baseArr,
          // },
          {
            label: "수입",
            data: incomeArr,
            backgroundColor: "#3E74FF",
          },
          {
            label: "지출",
            data: outcomeArr,
            backgroundColor: "#6AF0F0",
          },
          {
            label: "기말",
            data: totalArr,
            type: "line",
            fill: false,
            borderColor: "#00dd77",
            order: 3,
            borderWidth: 4,
          },
        ],
      });

      setTriggerSetData(false);
    }
  }, [triggerSetData]);

  return (
    <div id="GroupwareWorks">
      <GroupwareSideBar currentPath={groupCashflow} />
      <div className="commute_wrapper">
        <div id="GroupwareCashflow">
          <TopBanner />
          <Top page={step} />
          <div className="content_box">
            <div className="report_box">
              <div className="cashflow_report wrap">
                <div className="bg_wh rounded calc_box">
                  <p className="start_date">
                    {startDate.year}년 {startDate.month}월 {startDate.day}일
                    기준
                  </p>
                  <ul>
                    <li className="plus">
                      <strong className="cash_name">보유금액</strong>
                      <p className="cash">
                        <strong className="blue">
                          {reportSumData.baseCashAmount}
                        </strong>
                      </p>
                    </li>
                    <li className="minus">
                      <strong className="cash_name">예상 현금유입</strong>
                      <p className="cash">
                        <strong className="blue">
                          {reportSumData.totalIncome}
                        </strong>
                      </p>
                    </li>
                    <li>
                      <strong className="cash_name">예상 현금유출</strong>
                      <p className="cash">
                        <strong className="blue">
                          {reportSumData.totalOutcome}
                        </strong>
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="data_box bg_wh rounded mt-10">
                  <strong className="data_tit">월별 CASHFLOW</strong>
                  <div className="mon_tbl_box">
                    <div className="item head">
                      <div className="month">&nbsp;</div>
                      <div>기초</div>
                      <div>수입</div>
                      <div>지출</div>
                      <div>기말</div>
                    </div>
                    {monGraphData.map((data, idx) => (
                      <div key={idx} className="item mon_box">
                        <div className="month">
                          {startDate.month + idx > 12
                            ? startDate.month + idx - 12
                            : startDate.month + idx}
                          월
                        </div>
                        <div className="rAlign">
                          {formatValue(data.baseCashAmount)}
                        </div>
                        <div className="rAlign">{formatValue(data.income)}</div>
                        <div className="rAlign">
                          {formatValue(data.outcome)}
                        </div>
                        <div className="rAlign">
                          {formatValue(data.totalAmount)}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="graph_box">
                    <p className="unit">단위: 천원</p>
                    <Bar
                      data={mixedCahrtData}
                      options={chartOption}
                      width={882}
                      height={200}
                    />
                  </div>
                </div>
              </div>
              <div>
                <CashflowHistoryList />
              </div>
            </div>
            <div className="financing">
              <strong>자금조달 정보</strong>
              <ul className="link_list">
                {links.map((item, idx) => (
                  <li key={idx}>
                    <Link to={item.link}>
                      <div>
                        <strong>{item.title}</strong>
                        <p>{item.text}</p>
                        <div className="go">
                          <strong>바로가기&rarr;</strong>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CashflowReport;
