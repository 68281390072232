const PatentListOfProduct = ({
  patentList = [],
  insertPatentProduct,
  deletePatentProduct,
  sort,
  setPatentSort,
  getPatentListOfProduct,
  selectedProduct,
}) => {
  return (
    <div id="PatentListOfProduct">
      <table>
        <colgroup>
          <col width={70} />
          <col width={60} />
          <col />
          <col width={60} />
          <col />
          <col width={70} />
          <col width={60} />
          <col />
          <col />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>
              {patentList.length > 0 ? (
                sort === "related" ? (
                  <button
                    className="sort_btn"
                    onClick={() => {
                      getPatentListOfProduct(selectedProduct, "unrelated");
                    }}
                  >
                    연결순<i className="fi-rs-arrows-repeat"></i>
                  </button>
                ) : sort === "unrelated" ? (
                  <button
                    className="sort_btn"
                    onClick={() => {
                      getPatentListOfProduct(selectedProduct, "default");
                    }}
                  >
                    해제순<i className="fi-rs-arrows-repeat"></i>
                  </button>
                ) : (
                  <button
                    className="sort_btn"
                    onClick={() => {
                      getPatentListOfProduct(selectedProduct, "related");
                    }}
                  >
                    기본순<i className="fi-rs-arrows-repeat"></i>
                  </button>
                )
              ) : (
                ""
              )}
            </th>
            <th>순번</th>
            <th>제품/서비스</th>
            <th>국가</th>
            <th>출원번호</th>
            <th>
              SMART5
              <br />
              등급
            </th>
            <th>
              NOW
              <br />
              사업성
            </th>
            <th>등록번호</th>
            <th>현재상태</th>
            <th>발명의명칭</th>
          </tr>
        </thead>
        <tbody>
          {patentList.length > 0 ? (
            patentList.map((list, idx) => (
              <tr>
                <td>
                  {list.is_related ? (
                    <button
                      className="related_btn"
                      onClick={() => {
                        deletePatentProduct(list.idx, sort);
                      }}
                    >
                      해제
                    </button>
                  ) : (
                    <button
                      className="related_btn related"
                      onClick={() => {
                        insertPatentProduct(list.idx, sort);
                      }}
                    >
                      연결
                    </button>
                  )}
                </td>
                <td>{idx + 1}</td>
                <td>{list.name}</td>
                <td>{list.nation}</td>
                <td>{list.apply_number}</td>
                <td>{list.sm_grade}</td>
                <td>{list.now_grade}</td>
                <td>{list.register_number}</td>
                <td>{list.status}</td>
                <td style={{ textAlign: "left" }}>{list.invention_title}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10}>데이터가 없습니다.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PatentListOfProduct;
