import API from "../../util/api";

export default {
  getNews(payload) {
    return API.get("/info/ip/news", { params: payload });
  },
  getNewsInfo() {
    return API.get("/info/ip/news");
  },
  getYoutube(payload) {
    return API.get("/info/ip/youtube", { params: payload });
  },
  getYoutubeInfo() {
    return API.get("/info/ip/youtube/{youtube_idx}");
  },
  getCompanyCode(code) {
    return API.get("/info/company/code", { params: { code: code } });
  },
  getCompany(keyword, type, search_type) {
    return API.get("/info/company", {
      params: {
        keyword: keyword,
        type: type,
        search_type: 0,
        corporate: search_type,
      },
    });
  },
  getCompanyPatent(params) {
    return API.get("/info/patent", { params: params });
  },
  getNumberPatent(params) {
    return API.get("/info/patent/searchNumber", { params: params });
  },
  insertPatent(payload) {
    return API.post("/info/patent/insert", payload);
  },
  getTradeMark(params) {
    return API.get("/info/trademark", { params: params });
  },
  insertTrademark(payload) {
    return API.post("/info/trademark/insert", payload);
  },
  getDesign(params) {
    return API.get("/info/design", { params: params });
  },
  insertDesign(payload) {
    return API.post("/info/design/insert", payload);
  },
  insertCompanyResearchHistory(payload) {
    return API.post("info/company/company_search_history", payload);
  },
  getCompanyResearchHistory() {
    return API.get("info/company/get_company_search_history");
  },
  getMyPatent(params) {
    return API.get("/info/patent/myPatent", { params: params });
  },
  checkCompetitorDecreaseCount(params) {
    return API.get("/info/company/count_check", { params: params });
  },
};
