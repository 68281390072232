import { useEffect, useState } from "react";
import ProductAPI from "../../API/product";
import MiniLoading from "../common/MiniLoading";
import DetailPop from "./DetailPop";

const MarketMovement = () => {
  const [keywordList, setKeywordList] = useState([]);
  const [selectedKeword, setSelectedKeword] = useState({ idx: "", name: "" });
  const [marketInfo, setMarketInfo] = useState({
    domestic_market: "", //본 특허기술이 이용되는 국내 시장 정보
    foreign_market: "", //본 특허기술이 이용되는 해외 시장 정보
    domestic_company: "", //본 특허와 관련 깊은 국내 대표기업
    foreign_company: "", // 본 특허와 관련 깊은 해외 대표기업
    related_market: [], //나의 제품 연관 시장 정보
  });
  const [isLoading, setIsLoading] = useState({ market: false });
  const [detailPopOpen, setDetailPopOpen] = useState(false); // 나의 제품 연관 시장 정보 자세히 보기 팝업
  const [detailItem, setDetailItem] = useState({}); // 나의 제품 연관 시장 정보 자세히 보기 팝업 내용

  const chageKeyword = (item) => {
    console.log("idx:", item.idx);
    setSelectedKeword(item);
    getProductMarketInfo(item);
  };

  const getProductMarketInfo = (item) => {
    // 제품 관련 시장정보 가져오기
    setIsLoading((prev) => ({ ...prev, market: true }));
    ProductAPI.getProductMarketInfo({ keyword: item.name, count: 4 })
      .then((res) => {
        console.log("getProductMarketInfo:", res);
        if (res.status === 200) {
          setMarketInfo({
            domestic_market:
              res.data?.items?.gptMarketInfo?.[0]?.market_info || "",
            foreign_market:
              res.data?.items?.gptMarketInfo?.[0]?.for_market_info || "",
            domestic_company:
              res.data?.items?.gptMarketInfo?.[0]?.company || "",
            foreign_company:
              res.data?.items?.gptMarketInfo?.[0]?.for_company || "",
            related_market: res.data?.items?.marketInfo || [],
          }); // 나의 제품 시장 규모 파악하기 저장
        }
      })
      .catch((error) => {
        console.error("Error fetching market info:", error);
      })
      .finally(() => {
        setIsLoading((prev) => ({ ...prev, market: false }));
      });
  };

  const splitString = (str) => {
    if (str !== "") {
      return str.split("|");
    } else {
      return [];
    }
  };

  const Loading = () => {
    return (
      <div className="loading">
        <MiniLoading />
      </div>
    );
  };

  useEffect(() => {
    ProductAPI.getPatentProductList().then((res) => {
      // 제품리스트 가져오기
      console.log("getPatentProductList::", res);
      if (res.status === 200) {
        if (res.data?.items?.length > 0) {
          setSelectedKeword(res.data?.items[0]); // 첫 번째 제품 선택
          setKeywordList(res.data?.items); // 제품 리스트 세팅
          getProductMarketInfo(res.data?.items[0]); // 첫 번째 제품 시장정보 가져오기
        }
      }
    });
  }, []);

  useEffect(() => {
    console.log("keywordList:", keywordList);
  }, [keywordList]);

  return (
    <div id="MarketMovement">
      <div className="top_txt">
        <strong>나의 제품 시장 규모 파악하기</strong>
        <p>
          우리 회사 제품 시장규모를 파악하고, 대출을 실행할 특허를 선별해
          보세요.
        </p>
      </div>
      <div className="tag_list">
        <ul>
          {keywordList.length > 0
            ? keywordList.map((item) => (
                <li
                  className={
                    item.idx &&
                    selectedKeword &&
                    selectedKeword.idx === item.idx
                      ? "selected"
                      : ""
                  }
                  onClick={() => chageKeyword(item)}
                  key={item.idx}
                >
                  {item.name}
                </li>
              ))
            : ""}
        </ul>
      </div>
      <div className="info_box domestic">
        <strong className="info_tit">
          본 특허기술이 이용되는 국내 시장 정보
        </strong>
        <p>
          {isLoading.market ? (
            <Loading />
          ) : (
            marketInfo.domestic_market || "관련 시장 정보가 없습니다."
          )}
        </p>
      </div>
      <div className="info_box overseas">
        <strong className="info_tit">
          본 특허기술이 이용되는 해외 시장 정보
        </strong>
        <p>
          {isLoading.market ? (
            <Loading />
          ) : (
            marketInfo.foreign_market || "관련 시장 정보가 없습니다."
          )}
        </p>
      </div>
      <div className="info_box market">
        <strong className="info_tit">나의 제품 연관 시장 정보</strong>
        {isLoading.market ? (
          <Loading />
        ) : marketInfo.related_market.length > 0 ? (
          <ul className="market_list">
            {marketInfo.related_market.map((item, idx) => (
              <li key={idx}>
                <strong className="list_tit">{item.title}</strong>
                <img
                  src={window.$Global.getCDN(item.image_key)}
                  alt={item.title}
                />
                <p className="txt">{item.gpt_summary}</p>
                <div className="btn">
                  <button
                    onClick={() => {
                      setDetailItem(item);
                      setDetailPopOpen(true);
                    }}
                  >
                    자세히 보기
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>관련 시장 정보가 없습니다.</p>
        )}
      </div>

      <div className="info_box domestic_comp">
        <strong className="info_tit">본 특허와 관련 깊은 국내 대표기업</strong>
        {isLoading.market ? (
          <Loading />
        ) : splitString(marketInfo.domestic_company).length > 0 ? (
          <ul className="comp_list">
            {splitString(marketInfo.domestic_company).map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        ) : (
          <p>관련 기업 정보가 없습니다.</p>
        )}
        {/* <button className="more">연관기업 더보기</button> */}
      </div>
      <div className="info_box overseas_comp">
        <strong className="info_tit">본 특허와 관련 깊은 해외 대표기업</strong>
        {isLoading.market ? (
          <Loading />
        ) : splitString(marketInfo.foreign_company).length > 0 ? (
          <ul className="comp_list">
            {splitString(marketInfo.foreign_company).map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        ) : (
          <p>관련 기업 정보가 없습니다.</p>
        )}
        {/* <button className="more">연관기업 더보기</button> */}
      </div>
      {detailPopOpen && (
        <DetailPop item={detailItem} setDetailPopOpen={setDetailPopOpen} />
      )}
    </div>
  );
};

export default MarketMovement;
