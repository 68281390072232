const freeData = [
  {
    content: "업무 효율화",
    color: "#0078F0",
    isOpen: false,
    icon: "fi-rs-chart-network",
    child: [
      {
        content: "일정관리",
        pathname: "/groupware/schedule",
      },
      {
        content: "근태관리",
        pathname: "/groupware/attend",
      },
      {
        content: "인사관리",
        pathname: "/groupware/emp?section=branch&command=listBranch",
      },
      {
        content: "주소록",
        pathname: "/groupware/addressbook",
      },
      {
        content: "게시판",
        pathname: "/groupware/boardlist?section=board&command=list&boardno=101",
      },
    ],
  },
  {
    content: "자금 확보",
    color: "#00B2AA",
    isOpen: false,
    icon: "fi-rs-money-coin-transfer",
    child: [
      {
        content: "IP담보대출",
        pathname: "/mortgageLoan/list",
      },
      // {
      //   content: "투자유치정보",
      //   pathname: "/",
      // },
      {
        content: "상표거래",
        pathname: "/trade/list",
      },
    ],
  },
  {
    content: "매출 증대",
    color: "#00C198",
    isOpen: false,
    icon: "fi-rs-selling",
    child: [
      {
        content: "기업정보",
        pathname: "/competitor/main",
      },
    ],
  },
  {
    content: "정부과제수주",
    color: "#00CB8C",
    isOpen: false,
    icon: "fi-rs-government-flag",
    child: [
      {
        content: "정부사업 검색",
        pathname: "/taskManage/list",
      },
      {
        content: "맞춤 정부 사업 알림",
        pathname: "/taskManage/setting",
      },
      {
        content: "정부사업 콘텐츠",
        pathname: "/businessDescription/list",
      },
      {
        content: "사업계획서 작성",
        pathname: "/business/main",
      },
      {
        content: "주제별 정부 사업",
        pathname: "/taskManage/page2",
      },
    ],
  },
  {
    content: "특허 Value Up",
    color: "#00CB8C",
    isOpen: false,
    icon: "fi-rs-license",
    child: [
      {
        content: "특허/기술트렌드조사",
        pathname: "/researchTech/category",
      },
      {
        content: "연차료 관리",
        pathname: "/annual/graph",
      },
    ],
  },
  {
    content: "전략적 의사결정 지원",
    color: "#05DD7B",
    isOpen: false,
    icon: "fi-rs-head-side-brain",
    child: [
      {
        content: "시장정보",
        pathname: "/marketInfo/category",
      },
      {
        content: "기업기술역량 정보",
        pathname: "/ipHome/grade",
      },
      {
        content: "IP정보",
        pathname: "/researchTech/category",
      },
      {
        content: "기업정보",
        pathname: "/competitor/main",
      },
    ],
  },
  {
    content: "IP관리",
    pathname: "/ipHome",
    color: "#05DD7B",
    icon: "fi-rs-legal",
  },
];

const basicData = [
  {
    content: "업무 효율화",
    color: "#0078F0",
    isOpen: false,
    icon: "fi-rs-chart-network",
    child: [
      {
        content: "메일",
        pathname: "/groupware/mail",
      },
      {
        content: "전자결재",
        pathname: "/groupware/approval",
      },
      {
        content: "일정관리",
        pathname: "/groupware/schedule",
      },
      {
        content: "B-클라우드",
        pathname: "/groupware/cloud",
      },
      {
        content: "근태관리",
        pathname: "/groupware/attend",
      },
      {
        content: "휴가관리",
        pathname: "/groupware/vacation",
      },
      {
        content: "인사관리",
        pathname: "/groupware/emp?section=branch&command=listBranch",
      },
      {
        content: "주소록",
        pathname: "/groupware/addressbook",
      },
      {
        content: "게시판",
        pathname: "/groupware/boardlist?section=board&command=list&boardno=101",
      },
      {
        content: "예약/신청관리",
        pathname:
          "/groupware/works?section=reservation&command=conferenceReserve",
      },
    ],
  },
  {
    content: "실시간 경영활동 파악",
    color: "#0091D2",
    isOpen: false,
    icon: "fi-rs-lead-management",
    child: [
      {
        content: "업무보고",
        pathname: "/groupware/report/daily/ins",
      },
    ],
  },
  {
    content: "스마트 Cash 관리",
    color: "#00A3BC",
    isOpen: false,
    icon: "fi-rs-money-bill-transfer",
    child: [
      {
        content: "지출관리",
        pathname:
          "/groupware/works?section=expense&command=expenseWriteForm&doc_type=expense",
      },
    ],
  },
  {
    content: "자금 확보",
    color: "#00B2AA",
    isOpen: false,
    icon: "fi-rs-money-coin-transfer",
    child: [
      {
        content: "IP담보대출",
        pathname: "/mortgageLoan/list",
      },
      // {
      //   content: "투자유치정보",
      //   pathname: "/",
      // },
      {
        content: "상표거래",
        pathname: "/trade/list",
      },
    ],
  },
  {
    content: "매출 증대",
    color: "#00C198",
    isOpen: false,
    icon: "fi-rs-selling",
    child: [
      {
        content: "영업관리",
        pathname:
          "/groupware/works?section=extension&command=lstCustomerContractor",
      },
      {
        content: "기업정보",
        pathname: "/competitor/main",
      },
    ],
  },
  {
    content: "정부과제수주",
    color: "#00CB8C",
    isOpen: false,
    icon: "fi-rs-government-flag",
    child: [
      {
        content: "정부사업 검색",
        pathname: "/taskManage/list",
      },
      {
        content: "맞춤 정부 사업 알림",
        pathname: "/taskManage/setting",
      },
      {
        content: "정부사업 콘텐츠",
        pathname: "/businessDescription/list",
      },
      {
        content: "사업계획서 작성",
        pathname: "/business/main",
      },
      {
        content: "주제별 정부 사업",
        pathname: "/taskManage/page2",
      },
    ],
  },
  {
    content: "특허 Value Up",
    color: "#00CB8C",
    isOpen: false,
    icon: "fi-rs-license",
    child: [
      {
        content: "IP관리",
        pathname: "/ipHome",
      },
      {
        content: "특허/기술트렌드조사",
        pathname: "/researchTech/category",
      },
      {
        content: "연차료 관리",
        pathname: "/annual/graph",
      },
    ],
  },
  {
    content: "전략적 의사결정 지원",
    color: "#05DD7B",
    isOpen: false,
    icon: "fi-rs-head-side-brain",
    child: [
      {
        content: "시장정보",
        pathname: "/marketInfo/category",
      },
      {
        content: "기업기술역량 정보",
        pathname: "/ipHome/grade",
      },
      {
        content: "IP정보",
        pathname: "/researchTech/category",
      },
      {
        content: "기업정보",
        pathname: "/competitor/main",
      },
    ],
  },
  {
    content: "IP관리",
    pathname: "/ipHome",
    color: "#05DD7B",
    icon: "fi-rs-legal",
  },
];

const premiumData = [
  {
    content: "업무 효율화",
    color: "#0078F0",
    isOpen: false,
    icon: "fi-rs-chart-network",
    child: [
      {
        content: "메일",
        pathname: "/groupware/mail",
        // iframe_url:"/mail/mailPageLoading.do?reload=false&siteFlag=biznavi"
      },
      {
        content: "전자결재",
        pathname: "/groupware/approval",
        // iframe_url:""
      },
      {
        content: "일정관리",
        pathname: "/groupware/schedule",
      },
      {
        content: "B-클라우드",
        pathname: "/groupware/cloud",
      },
      {
        content: "근태관리",
        pathname: "/groupware/attend",
      },
      {
        content: "휴가관리",
        pathname: "/groupware/vacation",
      },
      {
        content: "인사관리",
        pathname: "/groupware/emp?section=branch&command=listBranch",
      },
      {
        content: "주소록",
        pathname: "/groupware/addressbook",
      },
      {
        content: "게시판",
        pathname: "/groupware/boardlist?section=board&command=list&boardno=101",
      },
      {
        content: "예약/신청관리",
        pathname:
          "/groupware/works?section=reservation&command=conferenceReserve",
      },
    ],
  },
  {
    content: "실시간 경영활동 파악",
    color: "#0091D2",
    isOpen: false,
    icon: "fi-rs-lead-management",
    child: [
      {
        content: "업무보고",
        pathname: "/groupware/report/daily/ins",
      },
      {
        content: "프로젝트 관리",
        pathname:
          "/groupware/office?section=command&command=showCommandList&method=receive_list",
      },
    ],
  },
  {
    content: "스마트 Cash 관리",
    color: "#00A3BC",
    isOpen: false,
    icon: "fi-rs-money-bill-transfer",
    child: [
      {
        content: "캐시플로우",
        // pathname: "/groupware/cashflow/landing",
        pathname: "/cashflow/intro",
      },
      {
        content: "지출관리",
        pathname:
          "/groupware/works?section=expense&command=expenseWriteForm&doc_type=expense",
      },
    ],
  },
  {
    content: "자금 확보",
    color: "#00B2AA",
    isOpen: false,
    icon: "fi-rs-money-coin-transfer",
    child: [
      {
        content: "IP담보대출",
        pathname: "/mortgageLoan/list",
      },
      // {
      //   content: "투자유치정보",
      //   pathname: "/",
      // },
      {
        content: "상표거래",
        pathname: "/trade/list",
      },
    ],
  },
  {
    content: "매출 증대",
    color: "#00C198",
    isOpen: false,
    icon: "fi-rs-selling",
    child: [
      {
        content: "영업관리",
        pathname:
          "/groupware/works?section=extension&command=lstCustomerContractor",
      },
      {
        content: "기업정보",
        pathname: "/competitor/main",
      },
    ],
  },
  {
    content: "정부과제수주",
    color: "#00CB8C",
    isOpen: false,
    icon: "fi-rs-government-flag",
    child: [
      {
        content: "정부사업 검색",
        pathname: "/taskManage/list",
      },
      {
        content: "맞춤 정부 사업 알림",
        pathname: "/taskManage/setting",
      },
      {
        content: "정부사업 콘텐츠",
        pathname: "/businessDescription/list",
      },
      {
        content: "사업계획서 작성",
        pathname: "/business/main",
      },
      {
        content: "주제별 정부 사업",
        pathname: "/taskManage/page2",
      },
    ],
  },
  {
    content: "특허 Value Up",
    color: "#00CB8C",
    isOpen: false,
    icon: "fi-rs-license",
    child: [
      {
        content: "IP관리",
        pathname: "/ipHome",
      },
      {
        content: "특허/기술트렌드조사",
        pathname: "/researchTech/category",
      },
      {
        content: "연차료 관리",
        pathname: "/annual/graph",
      },
    ],
  },
  {
    content: "전략적 의사결정 지원",
    color: "#05DD7B",
    isOpen: false,
    icon: "fi-rs-head-side-brain",
    child: [
      {
        content: "시장정보",
        pathname: "/marketInfo/category",
      },
      {
        content: "기업기술역량 정보",
        pathname: "/ipHome/grade",
      },
      {
        content: "IP정보",
        pathname: "/researchTech/category",
      },
      {
        content: "기업정보",
        pathname: "/competitor/main",
      },
    ],
  },
  {
    content: "IP관리",
    pathname: "/ipHome",
    color: "#05DD7B",
    icon: "fi-rs-legal",
  },
];

export const headerData = (idx, deadline) => {
  const now = Math.floor(Date.now() / 1000);
  let data;

  switch (idx) {
    case "ULU0W2VSZIN3O48P":
      if (now < deadline) {
        data = premiumData;
      } else {
        data = freeData;
      }
      break;
    case "IPV8WKP6NQ3U17AO":
      if (now < deadline) {
        data = basicData;
      } else {
        data = freeData;
      }
      break;
    case "4GX4DZ8Q77QBM5DO":
      if (now < deadline) {
        data = premiumData;
      } else {
        data = freeData;
      }
      break;
    default:
      data = freeData;
  }

  return data;
};
