import React, { useEffect, useState } from "react";
import "./css/patentApplicationInfo.scss";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import CheckBox from "../common/CheckBox";
import PatentApplicationAPI from "../../API/patentApplication/index";
import ProductAPI from "../../API/product/index";
import TableRow from "./row/TableRow";
import DetailPopupDownload from "../common/DetailPopupDownload";
import DetailPopupUpload from "../common/DetailPopupUpload";
import MiniLoading from "../common/MiniLoading";

function PatentApplicationInfoListNew({
  setIsRegistPopOpen,
  setIsNeedGetProduct,
  triggergetPatentList,
  setTriggergetPatentList,
}) {
  let table_head_tag = [<th>선택</th>],
    tab_tags = [],
    options_tag = [];

  const cur_year = new Date().getFullYear();
  const [tabState, setTabState] = useState(false); // Tab 설정 활성화 여부
  const [selectedBasics, setSelectedBasics] = useState(true); // Tab 기본으로 설정 on / off
  const [editing, setEditing] = useState(false);

  const [table_head, setTableHead] = useState([]); // 테이블 헤더값
  const [keywordType, setKeywordType] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [tbody, setTbody] = useState([]);
  const [data_list, setDataList] = useState([]);
  const [columnShow, setColumnShow] = useState({
    checkbox: true,
    no: true,
    name: true,
    patent_no: true,
    nation: true,
    apply_number: true,
    register_number: true,
    state: true,
    invention_name: true,
    product_state: true,
    relative_file: true,
    amount: true,
  });
  const [productIdx, setProductIdx] = useState(null);
  const [updateProductList, setUpdateProductList] = useState([]);
  const [updateProductPatentList, setUpdateProductPatentList] = useState([]);

  const [downPopupShow, setDownPopupShow] = useState(false);
  const [upPopupShow, setUpPopupShow] = useState(false);
  const [fileViewList, setFileViewList] = useState([]); // 등록된 파일 리스트
  const [uploadFileViewList, setUploadFileViewList] = useState([]); // 업로드 파일 리스트

  // tab 설정 state
  // text: checkbox 이름, key: api 통신할 때의 key 값, checked: true인 값이 table_head에 추가됨(초기 값이 기본 체크 값이 됨), defaultChecked: 초기화를 위해 기본 체크값이 어떤 거였는지 알기 위해 정의한 값,
  // 초기 checked 1인 값이 테이블 기본 head 값이 됨.
  const [btns_state_tab, setBtnsStateTab] = useState([]);
  const [isPatentLoading, setIsPatentLoading] = useState(false);
  const [checkboxList, setCheckboxList] = useState([]); // 선택된 제품

  useEffect(() => {
    drawTable();
  }, [data_list, columnShow, editing]);

  useEffect(() => {
    setCheckboxList(new Array(data_list.length).fill(false));
  }, [data_list.length]);

  useEffect(() => {
    // 제품 등록하기 팝업 닫을때 변경사항이 생겼다면 리스트 가져오기 실행
    if (triggergetPatentList) {
      getPatentApplicationInfo();
    }
  }, [triggergetPatentList]);

  useEffect(() => {
    getPatentApplicationInfo();
    if (window.localStorage.getItem("patentApplication_tab_init")) {
      window.localStorage.setItem("patentApplication_no", "1");
      window.localStorage.setItem("patentApplication_name", "1");
      window.localStorage.setItem("patentApplication_patent_no", "1");
      window.localStorage.setItem("patentApplication_nation", "1");
      window.localStorage.setItem("patentApplication_apply_number", "1");
      window.localStorage.setItem("patentApplication_register_number", "1");
      window.localStorage.setItem("patentApplication_state", "1");
      window.localStorage.setItem("patentApplication_invention_name", "1");
      window.localStorage.setItem("patentApplication_product_state", "1");
      window.localStorage.setItem("patentApplication_relative_file", "1");
      window.localStorage.setItem("patentApplication_amount", "1");
    } else {
      setSelectedBasics(false);
    }

    let no = window.localStorage.getItem("patentApplication_no");
    let name = window.localStorage.getItem("patentApplication_name");
    let patent_no = window.localStorage.getItem("patentApplication_patent_no");
    let nation = window.localStorage.getItem("patentApplication_nation");
    let apply_number = window.localStorage.getItem(
      "patentApplication_apply_number"
    );
    let register_number = window.localStorage.getItem(
      "patentApplication_register_number"
    );
    let state = window.localStorage.getItem("patentApplication_state");
    let invention_name = window.localStorage.getItem(
      "patentApplication_invention_name"
    );
    let product_state = window.localStorage.getItem(
      "patentApplication_product_state"
    );
    let relative_file = window.localStorage.getItem(
      "patentApplication_relative_file"
    );
    let amount = window.localStorage.getItem("patentApplication_amount");

    setColumnShow({
      no: no == null ? true : Number(no) ? true : false,
      name: name == null ? true : Number(name) ? true : false,
      patent_no: patent_no == null ? true : Number(patent_no) ? true : false,
      nation: nation == null ? true : Number(nation) ? true : false,
      apply_number:
        apply_number == null ? true : Number(apply_number) ? true : false,
      register_number:
        register_number == null ? true : Number(register_number) ? true : false,
      state: state == null ? true : Number(state) ? true : false,
      invention_name:
        invention_name == null ? true : Number(invention_name) ? true : false,
      product_state:
        product_state == null ? true : Number(product_state) ? true : false,
      relative_file:
        relative_file == null ? true : Number(relative_file) ? true : false,
      amount: amount == null ? true : Number(amount) ? true : false,
      checkbox: no == null ? true : Number(no) ? true : false,
    });

    setBtnsStateTab([
      {
        text: "순번",
        key: "no",
        type: null,
        checked:
          window.localStorage.getItem("patentApplication_no") == null
            ? 1
            : Number(window.localStorage.getItem("patentApplication_no")),
        defaultChecked: 1,
      },
      {
        text: "제품/서비스",
        key: "name",
        type: 0,
        checked:
          window.localStorage.getItem("patentApplication_name") == null
            ? 1
            : Number(window.localStorage.getItem("patentApplication_name")),
        defaultChecked: 1,
      },
      {
        text: "특허 순번",
        key: "patent_no",
        type: null,
        checked:
          window.localStorage.getItem("patentApplication_patent_no") == null
            ? 1
            : Number(
                window.localStorage.getItem("patentApplication_patent_no")
              ),
        defaultChecked: 1,
      },
      {
        text: "국가",
        key: "nation",
        type: 1,
        checked:
          window.localStorage.getItem("patentApplication_nation") == null
            ? 1
            : Number(window.localStorage.getItem("patentApplication_nation")),
        defaultChecked: 1,
      },
      {
        text: "출원번호",
        key: "apply_number",
        type: 5,
        checked:
          window.localStorage.getItem("patentApplication_apply_number") == null
            ? 1
            : Number(
                window.localStorage.getItem("patentApplication_apply_number")
              ),
        defaultChecked: 1,
      },
      {
        text: "SMART3<br/>등급",
        key: "sm_grade",
        type: null,
        checked: 1,
        defaultChecked: 1,
      },
      {
        text: "NOW<br/>사업성",
        key: "now_grade",
        type: null,
        checked: 1,
        defaultChecked: 1,
      },
      {
        text: "등록번호",
        key: "register_number",
        type: 2,
        checked:
          window.localStorage.getItem("patentApplication_register_number") ==
          null
            ? 1
            : Number(
                window.localStorage.getItem("patentApplication_register_number")
              ),
        defaultChecked: 1,
      },
      {
        text: "현재상태",
        key: "state",
        type: 3,
        checked:
          window.localStorage.getItem("patentApplication_state") == null
            ? 1
            : Number(window.localStorage.getItem("patentApplication_state")),
        defaultChecked: 1,
      },
      {
        text: "발명의 명칭",
        key: "invention_name",
        type: 4,
        checked:
          window.localStorage.getItem("patentApplication_invention_name") ==
          null
            ? 1
            : Number(
                window.localStorage.getItem("patentApplication_invention_name")
              ),
        defaultChecked: 1,
      },
      {
        text: "적용유무",
        key: "product_state",
        type: null,
        checked:
          window.localStorage.getItem("patentApplication_product_state") == null
            ? 1
            : Number(
                window.localStorage.getItem("patentApplication_product_state")
              ),
        defaultChecked: 1,
      },
      {
        text: "관련자료",
        key: "relative_file",
        type: null,
        checked:
          window.localStorage.getItem("patentApplication_relative_file") == null
            ? 1
            : Number(
                window.localStorage.getItem("patentApplication_relative_file")
              ),
        defaultChecked: 1,
      },
      {
        text: "매출액 (전년 - 추후 3년)",
        key: "amount",
        type: null,
        checked:
          window.localStorage.getItem("patentApplication_amount") == null
            ? 1
            : Number(window.localStorage.getItem("patentApplication_amount")),
        defaultChecked: 1,
      },
    ]);
  }, []);

  const getPatentApplicationInfo = () => {
    setIsPatentLoading(true);
    setCheckboxList([]);
    let payload = {
      type: keywordType,
      keyword: keyword,
    };

    PatentApplicationAPI.getPatentApplicationInfo(
      payload.type,
      payload.keyword
    ).then((res) => {
      setDataList(res.data);
      setCheckboxList(new Array(res.data.length).fill(false));
      setIsPatentLoading(false);
    });

    setIsNeedGetProduct(false);
    setTriggergetPatentList(false);
  };

  const onChagneKeywordType = (e) => {
    setKeywordType(e.target.value);
  };

  const drawTable = () => {
    let tags = [];

    for (let i = 0; i < data_list.length; i++) {
      let data = data_list[i];
      let first_patent = data.patent.length ? data.patent[0] : {};
      let sales = data.sales;
      let rowSpan = data.patent.length;

      tags.push(
        <TableRow
          key={i}
          index={i}
          editing={editing}
          rowSpan={rowSpan || 1}
          emptyInfo={window.$Global.isEmptyObject(first_patent)}
          number={i + 1}
          nation_string={first_patent.nation_string}
          apply_number={first_patent.apply_number}
          patent_idx={first_patent.idx}
          product_idx={data.idx}
          name={data.name}
          state={first_patent.state}
          invention_name={first_patent.invention_name}
          product_state={first_patent.product_state}
          cur_year={cur_year}
          sales={sales}
          register_number={first_patent.register_number}
          afterShow={true}
          columnShow={columnShow}
          onClickInput={onClickInput}
          onChange={onChange}
          onClickDownPopShow={onClickDownPopShow}
          onClickUpPopShow={onClickUpPopShow}
          checkboxList={checkboxList}
          setCheckboxList={setCheckboxList}
          setCheckboxListNotClone={true}
        />
      );

      for (let j = 1; j < rowSpan; j++) {
        let item = data.patent[j];

        tags.push(
          <TableRow
            key={i + "-" + j}
            editing={editing}
            rowSpan={rowSpan || 1}
            number={j + 1}
            nation_string={item.nation_string}
            apply_number={item.apply_number}
            patent_idx={item.idx}
            product_idx={data.idx}
            state={item.state}
            invention_name={item.invention_name}
            product_state={item.product_state}
            sales={sales}
            sm_grade={item.sm_grade}
            now_grade={item.now_grade}
            register_number={item.register_number}
            afterShow={false}
            columnShow={columnShow}
            onClickInput={onClickInput}
            onChange={onChange}
            onClickDownPopShow={onClickDownPopShow}
            onClickUpPopShow={onClickUpPopShow}
          />
        );
      }
    }
    if (tags.length) {
      setTbody(tags);
    } else {
      setTbody([window.$Global.notTd(20, "데이터가 없습니다")]);
    }
  };

  const onClickToggleSelectedBasics = () => {
    // 기본으로 설정 ON 일때 체크 값 기본으로 설정
    if (!selectedBasics) {
      let copy_tab = window.$Global.cloneObject(btns_state_tab);
      let copy_arr_tab = Object.keys(copy_tab).map((key) => copy_tab[key]);
      copy_arr_tab.forEach((item) => {
        item.checked = item.defaultChecked;
      });
      setBtnsStateTab(copy_arr_tab);
      setColumnShow({
        no: true,
        name: true,
        patent_no: true,
        nation: true,
        apply_number: true,
        register_number: true,
        state: true,
        invention_name: true,
        product_state: true,
        relative_file: true,
        amount: true,
      });
    }
    setSelectedBasics(!selectedBasics);
  };

  const onChangeTabChecked = (input, text) => {
    let copy_btns_state_tab = btns_state_tab.concat([]);
    var item = copy_btns_state_tab.find((item) => item.text === text);

    item.checked = Number(input.target.checked);
    setBtnsStateTab(copy_btns_state_tab);

    let copy_column = window.$Global.cloneObject(columnShow);
    copy_column[item.key] = input.target.checked;
    setColumnShow(copy_column);

    if (item.checked) {
      table_head_tag.push(<th>{item.text}</th>);
      setTableHead(table_head.concat([]));
    } else {
      let findItem = table_head.find((item) => item === item.text);
      let idx = table_head.indexOf(findItem);
      setTableHead(table_head.splice(idx, 1));
    }
  };

  const onClickTabSave = () => {
    let copy_tab = window.$Global.cloneObject(btns_state_tab);
    let copy_arr_tab = Object.keys(copy_tab).map((key) => copy_tab[key]);

    // 기본 탭 설정일때
    if (selectedBasics) {
      window.localStorage.setItem("patentApplication_tab_init", "1");
      copy_arr_tab.forEach((item) => {
        item.checked = item.defaultChecked;
      });
    } else {
      window.localStorage.removeItem("patentApplication_tab_init");
      copy_arr_tab.forEach((item) => {
        window.localStorage.setItem(
          "patentApplication_" + item.key,
          item.checked
        );
      });
    }
    setTabState(false);
  };

  const onClickDownload = (file_idx) => {
    PatentApplicationAPI.downloadProductPatentFile(productIdx, file_idx);
  };

  const onClickDeleteFileInDownload = (file_idx) => {
    PatentApplicationAPI.deleteProductPatentFile(productIdx, file_idx).then(
      (res) => {
        alert("삭제되었습니다");
        setFileViewList(
          fileViewList.filter((file) => {
            return file.idx !== file_idx;
          })
        );
      }
    );
  };

  const onClickUpload = () => {
    var formData = new FormData();

    uploadFileViewList.forEach((item) => {
      formData.append("file", item.file);
    });

    PatentApplicationAPI.uploadProductPatentFile(productIdx, formData).then(
      () => {
        alert("업로드되었습니다");
        setUpPopupShow(false);
        setUploadFileViewList([]);
      }
    );
  };

  const onClickDownPopShow = (product_idx) => {
    setProductIdx(product_idx);
    PatentApplicationAPI.getProductPatentFileList(product_idx).then((res) => {
      setDownPopupShow(true);
      setFileViewList(res.data);
    });
  };

  const onClickUpPopShow = (product_idx) => {
    setProductIdx(product_idx);
    setUpPopupShow(true);
  };

  const onClickInput = (type, product_idx, value, year) => {
    let arr = initDataListCopy();
    let item = arr.find((item) => item.idx == product_idx);

    if (item) {
      if (type == "name") {
        item.name = value;
      } else if (type == "amount") {
        let find_idx = item.sales.findIndex((item) => item.year == year);

        if (find_idx > -1) {
          item.sales[find_idx] = { year: year, amount: value };
        } else {
          item.sales.push({ year: year, amount: value });
        }
      }
    }
    setDataList(arr);

    let copy_arr = updateProductList.concat(product_idx);
    copy_arr = copy_arr.filter(
      (item, index) => copy_arr.indexOf(item) === index
    );
    setUpdateProductList(copy_arr);
  };

  const onChange = (product_idx, patent_idx, value) => {
    let arr = initDataListCopy();
    let item = arr.find((item) => item.idx == product_idx);

    if (item) {
      let find_item = item.patent.find((item) => item.idx == patent_idx);
      find_item.product_state = Number(value);
    }
    setDataList(arr);

    let copy_arr_patent = updateProductPatentList.concat(patent_idx);
    copy_arr_patent = copy_arr_patent.filter(
      (item, index) => copy_arr_patent.indexOf(item) === index
    );
    setUpdateProductPatentList(copy_arr_patent);

    let copy_arr_product = updateProductList.concat(product_idx);
    copy_arr_product = copy_arr_product.filter(
      (item, index) => copy_arr_product.indexOf(item) === index
    );
    setUpdateProductList(copy_arr_product);
  };

  const initDataListCopy = () => {
    let copy_list = window.$Global.cloneObject(data_list);
    let arr = Object.keys(copy_list).map((key) => copy_list[key]);

    if (!arr.length) return;

    for (let i = 0; i < arr.length; i++) {
      arr[i].patent = Object.keys(arr[i].patent).map(
        (key) => arr[i].patent[key]
      );
      arr[i].sales = Object.keys(arr[i].sales).map((key) => arr[i].sales[key]);
    }

    return arr;
  };

  const onClickSave = () => {
    for (let i = 0; i < updateProductList.length; i++) {
      let item = data_list.find((item) => item.idx == updateProductList[i]);

      if (item) {
        let payload = {
          name: item.name,
          sales: item.sales || 0,
        };
        PatentApplicationAPI.updateProductInfo(updateProductList[i], payload);

        for (let j = 0; j < item.patent.length; j++) {
          let patent_item = item.patent.find(
            (data) => data.idx == updateProductPatentList[j]
          );
          if (patent_item)
            PatentApplicationAPI.updateProductState(
              item.patent[j].idx,
              updateProductList[i],
              item.patent[j].product_state
            );
        }
      }
    }
    setEditing(false);
  };

  btns_state_tab.forEach((item, i) => {
    if (item.checked) {
      if (
        item.text == "순번" ||
        item.text == "국가" ||
        item.text == "특허 순번" ||
        item.text == "관련자료"
      ) {
        table_head_tag.push(<th key={i}>{item.text}</th>);
      } else if (item.text == "현재상태" || item.text == "제품/서비스") {
        table_head_tag.push(<th key={i}>{item.text}</th>);
      } else if (item.text == "매출액 (전년 - 추후 3년)") {
        table_head_tag.push(
          <th key={i} colSpan={5}>
            매출액 (전년 - 추후 3년)
          </th>
        );
      } else {
        table_head_tag.push(
          <th key={i} dangerouslySetInnerHTML={{ __html: item.text }} />
        );
      }

      if (
        item.text == "제품/서비스" ||
        item.text == "국가" ||
        item.text == "등록번호" ||
        item.text == "현재상태" ||
        item.text == "발명의 명칭" ||
        item.text == "출원번호"
      ) {
        options_tag.push(
          <option key={i} value={item.type}>
            {item.text}
          </option>
        );
      }
    }

    if (item.key === "now_grade" || item.key === "sm_grade") return;

    tab_tags.push(
      <CheckBox
        key={i}
        text={item.text}
        checked={item.checked}
        onChangeChecked={onChangeTabChecked}
        disabled={selectedBasics}
      />
    );
  });

  const downloadExcelFile = () => {
    console.log("엑셀 다운로드");
    let selectedIdx = [];
    if (
      checkboxList.length === 0 ||
      checkboxList.every((item) => item === false)
    ) {
      alert("제품을 선택해주세요.");
      return;
    }

    checkboxList.map((item, index) => {
      if (item) {
        selectedIdx.push(data_list[index].idx);
        console.log("selectedIdx:", selectedIdx);
      }
    });

    if (selectedIdx.length > 0) {
      ProductAPI.downloadExcel({ product_idx: selectedIdx });
    }
  };

  return (
    <div id="InvestmentView">
      <div className="header">
        {tabState || (
          <>
            <div className="wrap_search">
              <select onChange={onChagneKeywordType}>{options_tag}</select>
              <input
                type="text"
                className="input_search"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="검색어를 입력해주세요"
                onKeyUp={(e) => {
                  if (e.key === "Enter") getPatentApplicationInfo();
                }}
              />
              <button onClick={getPatentApplicationInfo}>
                <i className="icon_search_blue"></i>
              </button>
            </div>
          </>
        )}
        <div className="btns">
          {tabState ? (
            <>
              <ToggleButtonWithSpan
                text="Tab 기본 설정하기 "
                condition={{ on: "ON", off: "OFF" }}
                selected={selectedBasics}
                toggleSelected={onClickToggleSelectedBasics}
              />
              <button className="btn_tab_setting_save" onClick={onClickTabSave}>
                Tab 설정 저장
              </button>
            </>
          ) : editing ? (
            <>
              <button className="btn_save" onClick={onClickSave}>
                저장
              </button>
              <button className="btn_update" onClick={() => setEditing(false)}>
                취소
              </button>
            </>
          ) : (
            <>
              {/*<button className="btn_print" onClick={() => alert("기능 구현 예정입니다")}>프린트하기</button>*/}
              <button
                className="btn_update"
                onClick={() => {
                  setEditing(true);
                }}
              >
                수정하기
              </button>
              {checkboxList?.length > 0 ? (
                <button
                  className="btn_download"
                  onClick={() => {
                    downloadExcelFile();
                  }}
                >
                  선택 다운로드
                </button>
              ) : (
                ""
              )}

              <button
                className="btn_save"
                onClick={() => setIsRegistPopOpen((prev) => !prev)}
              >
                제품 등록하기
              </button>
              {/* <button
                className="btn_tab_setting"
                onClick={() => setTabState(true)}
              >
                Tab 설정
              </button> */}
            </>
          )}
        </div>
      </div>
      <div className="wrap_tab_btns">{tabState && tab_tags}</div>
      <div className="table-box-wrap not_align">
        <div className="wrap_list">
          {isPatentLoading ? (
            <div className="loading">
              <MiniLoading />
            </div>
          ) : (
            <table className="list">
              <colgroup>
                <col width={60} />
                <col width={60} />
                <col width={70} />
                <col width={60} />
                <col width={60} />
                <col width={200} />
                <col width={80} />
                <col width={80} />
                <col width={200} />
                <col width={90} />
                <col width={400} />
                <col width={80} />
                <col width={80} />
                <col width={80} />
                <col width={80} />
                <col width={80} />
                <col width={80} />
                <col width={80} />
              </colgroup>
              <thead>
                <tr>{table_head_tag}</tr>
              </thead>
              <tbody>{tbody}</tbody>
            </table>
          )}
        </div>
      </div>
      <DetailPopupDownload
        show={downPopupShow}
        setShow={setDownPopupShow}
        fileViewList={fileViewList}
        setFileList={setFileViewList}
        onClickDownload={onClickDownload}
        onClickDelete={onClickDeleteFileInDownload}
      />
      <DetailPopupUpload
        show={upPopupShow}
        setShow={setUpPopupShow}
        uploadFileViewList={uploadFileViewList}
        setFileList={setUploadFileViewList}
        onClickUpload={onClickUpload}
      />
    </div>
  );
}

export default React.memo(PatentApplicationInfoListNew);
