import "./scss/Top.scss";

const Top = ({ page }) => {
  return (
    <div id="Top">
      <div className={`top_bar ${page === "step" ? "green_bg" : "blue_bg"}`}>
        <div className="wrap">
          <h3>NOW</h3>
          <p>
            차근차근 STEP 따라 정보를 입력하고 RUNWAY 예상 리포트를 받아보세요!
          </p>
          {page === "step" ? <p className="start">작성중</p> : ""}
        </div>
      </div>
    </div>
  );
};

export default Top;
