import GroupwareSideBar from "./GroupwareSideBar";
import {
  groupWareWorksPath,
  groupWareExpensionWorksPath,
} from "./groupwarePath";
import "./css/GroupwareWorks.scss";
import { useLocation } from "react-router-dom";
import "moment/locale/ko";
import { param } from "express/lib/router";
import config from "../../const/config";
import React, { useEffect, useState, useRef, useContext } from "react";
import util from "../../util/util";
import IframeResizer from "iframe-resizer-react";
import CommonContext from "../../store/Common";

const getAppPathExt = (pathObject) => {
  var src = config.gwUrl + "/";
  var tmp = "?";
  pathObject.map = (key, val) => {
    tmp += "&" + key + "=" + val;
  };
  return src + tmp;
};

const getAppPath = (section, command, doc_type, params) => {
  var src = config.gwUrl + "/";
  let strParam = "";
  if (section == "reservation") {
    switch (command) {
      case "vehicleReserve":
        src += "vehicle.do";
        break;
      case "insBusinessCardForm":
      case "lstBusinessCard":
        src += "rental.auth.do";
        break;
      case "conferenceReserve":
      default:
        src += "conference.do";
        break;
    }
  } else if (section == "extension") {
    switch (command) {
      case "lstCustomerContractor":
        src += "contractor.auth.do";
        break;
      default:
        src += section + ".auth.do";
        break;
    }
  } else if (section == "approval") {
    src += section + ".person.do";
  } else if (section == "vacationList") {
    src += "doc.auth.do";
  } else if (section == "expense") {
    src += "doc.auth.do";
  } else if (section == "docView") {
    let lstPart = params.get("lstPart");
    strParam += "&idx=" + params.get("idx");
    strParam += "&doc_knd=" + params.get("doc_knd");
    strParam += "&doc_status=" + params.get("doc_status");
    strParam += "&doc_status_name=" + params.get("doc_status_name");
    strParam += "&list_mode=" + params.get("list_mode");
    strParam += "&lstPart=" + params.get("lstPart");
    if (lstPart == "doc") {
      src += "doc.auth.do";
    } else if (lstPart == "expense") {
      src += "doc.auth.do";
    } else {
      src += "vacation.auth.do";
    }
  } else if (section == "command") {
    strParam += "&idx=" + params.get("idx");
    strParam += "&method=" + params.get("method");
    src += "command.information.do";
  } else if (section == "docList") {
    src += "doc.auth.do";
  } else {
    src += section + ".auth.do";
  }

  if (section == "vacationList") {
    return src + "?command=" + command + "&lstPart=" + doc_type;
  } else if (section == "board") {
    switch (command) {
      case "view":
        src +=
          "?command=" +
          command +
          "&boardno=" +
          params.get("boardno") +
          "&pid=" +
          params.get("pid");
        break;
      default:
        src += "?command=" + command + "&boardno=" + doc_type;
        break;
    }

    return src; // + "?command=" + command + "&boardno=" + doc_type;
  } else if (section == "docView" || section == "command") {
    return src + "?command=" + command + strParam;
  } else {
    switch (command) {
      case "approvalList":
        src += "?command=" + command + "&list_type=" + params.get("list_type");
        break;

      // 전자결재 > 올린결재 > 상신문서 / 결재완료함 구분
      case "myList":
        if (params.get("status")) {
          src += "?command=" + command + "&status=" + params.get("status");
        } else {
          src += "?command=" + command + "&doc_type=" + doc_type;
        }
        break;

      default: //+ "&siteFlag=biznavi";
        src += "?command=" + command + "&doc_type=" + doc_type;
        //src += "?command=" + command + "&doc_type=" + doc_type;
        break;
    }
    return src;
  }
};

const GroupwareWorks = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [adminMenu, setAdminMenu] = useState([]);
  const [gwMenu, setGwMenu] = useState([]);
  const iframeRef = useRef(null);
  const { isMobile } = useContext(CommonContext);

  var isSection = !params.get("section") ? "doc" : params.get("section");
  var isCommand = !params.get("command") ? "writeForm" : params.get("command");
  var isDocType = !params.get("doc_type") ? "draft" : params.get("doc_type");
  var isApp =
    getAppPath(isSection, isCommand, isDocType, params) + "&siteFlag=biznavi";
  const dummy = params.get("dummy");
  //var _isApp     = getAppPathExt({"section":isSection, "command":isCommand, "doc_type":isDocType, "lstPart", });
  let sideBarSection = "";
  let adminMenuCd = "";
  //alert(isApp);
  if (
    isSection === "approval" ||
    isSection === "docView" ||
    isSection === "docList" ||
    isSection === "doc"
  ) {
    sideBarSection = "approval";
    //전자결재 관리자
  } else if (
    isSection === "vacation" &&
    (isDocType === "100108" || isDocType === "100095")
  ) {
    sideBarSection = "attend";
  } else if (isSection === "expense" || isSection === "extension") {
    sideBarSection = "expense";
  } else {
    sideBarSection = isSection;
  }
  //alert(sideBarSection);
  // useEffect(() => {
  //   window.addEventListener(
  //     "message",
  //     (e) => {
  //       //alert(e.data.iframeHeight);
  //       var iHeight = e.data.iframeHeight;
  //       if (iHeight === undefined) {
  //         return;
  //       } else {
  //         if (iHeight < 800) {
  //           iHeight = 800;
  //         }
  //         document.getElementById("contentWrap").height = iHeight + 20;
  //       }
  //     },
  //     false
  //   );
  // }, []);

  useEffect(() => {
    getAdminMenu();
    //resizeIframe();
  }, []);

  // useEffect(() => {
  //   resizeIframe();
  // }, [location]);

  // const resizeIframe = () => {
  //   // iframe 높이 조절
  //   const iframe = iframeRef.current;
  //   const handleIframeLoad = () => {
  //     iframe.height = 0;
  //     let newHeight = iframe.contentWindow.document.body.scrollHeight;
  //     iframe.height = newHeight;
  //   };
  //   iframe.addEventListener('load', handleIframeLoad);

  //   return () => {
  //     window.removeEventListener('load', handleIframeLoad);
  //   };
  // };

  const getAdminMenu = () => {
    let param = {
      su_menu_cd: "",
      menuTitle: "관리자",
      isContentWrap: true,
      isOpen: false,
    };

    let gwParam = {
      su_menu_cd: "",
      menuTitle: "",
      isContentWrap: true,
      isOpen: false,
    };

    if (sideBarSection == "approval") {
      param.su_menu_cd = "100006";
      util.getGroupwareAdminMenu(setAdminMenu, param);
    } else if (sideBarSection == "expense") {
      param.su_menu_cd = "200247";
      util.getGroupwareAdminMenu(setAdminMenu, param);

      // 출/퇴근관리 관리자 메뉴 사라지는 현상 수정
    } else if (sideBarSection == "attend") {
      param.su_menu_cd = "100009";
      util.getGroupwareAdminMenu(setAdminMenu, param);
    } else if (sideBarSection == "board") {
      gwParam.su_menu_cd = "200057";
      gwParam.menuTitle = "게시판";
      gwParam.isOpen = true;
      util.getGroupwareMenu(setGwMenu, gwParam);

      param.su_menu_cd = "200345";
      param.menu_cd = "200290";
      param.menuTitle = "관리자";
      param.isOpen = false;
      util.getGroupwareAdminMenu(setAdminMenu, param);

      // 예약관리용 관리자 메뉴
    } else if (sideBarSection == "reservation") {
      param.su_menu_cd = "200151";
      util.getGroupwareAdminMenu(setAdminMenu, param);
    }
  };

  //console.log(isApp);
  return (
    <div id="GroupwareWorks">
      {sideBarSection == "board" ? (
        <GroupwareSideBar
          currentPath={gwMenu}
          adminMenu={adminMenu}
          sidebar={sideBarSection}
        />
      ) : (
        <GroupwareSideBar
          currentPath={groupWareWorksPath[sideBarSection]}
          adminMenu={adminMenu}
          sidebar={sideBarSection}
        />
      )}
      <div className="commute_wrapper">
        {/* <iframe
          id="contentWrap"
          src={isApp}
          width="100%"
          height="800px"
          ref={iframeRef}
        /> */}
        <IframeResizer
          id="contentWrap"
          src={isApp + `&dummy=${dummy}`}
          heightCalculationMethod="bodyScroll"
        />
      </div>
    </div>
  );
};

export default GroupwareWorks;
